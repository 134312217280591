<header class="header fixed-top header-{{ theme }}" [class.position-absolute]="!isFixed">
  <nav class="nav align-items-center d-none d-lg-flex">
    <ng-container *ngFor="let item of navItems; let last = last">
      <a
        *ngIf="item.url"
        (click)="scrollToTop(item.url)"
        [routerLink]="getRouterLink(item)"
        class="nav-item nav-link font-size-16"
        [class.pr-0]="last"
      >
        {{ item.title | translate}}
      </a>
      <a *ngIf="item.externalUrl" href="{{ onLogin() }}" class="nav-item nav-link font-size-16"
         [attr.target]="item.target">
        {{ item.title | translate}}
      </a>
    </ng-container>
  </nav>
  <a [routerLink]="getHomeRoute" (click)="scrollToTop('/')" class="header-logo">
    <img src="/assets/images/logo/{{
        theme === 'light' ? 'color' : 'white'
      }}_text.svg" width="151" alt="Optimo logo" loading='lazy'/>
  </a>

  <div class="header-info d-flex align-items-center">
    <a class="phone-button-icon pr-2 pl-3 py-3" aria-label="Phone number" href="tel:{{phoneNumber}}">
      <app-icon icon='header-phone'></app-icon>
    </a>
    <div class="phone-number">
      <a class="font-color-{{!requestButtonVisibility && landing ?'white': 'black' }} font-size-16"
         href="tel:{{phoneNumber}}" aria-label="Phone number">
        {{ phoneNumber }}
      </a>
    </div>
    <div class="language-select-wrapper d-flex align-items-center">
      <app-language-switcher
        [icon]="!requestButtonVisibility && landing ? 'globe-white' : 'globe'">
      </app-language-switcher>
    </div>
    <div class="align-items-center d-none d-lg-flex">
      <a
        href="{{ onLogin() }}" target="_blank"
        class="btn-request font-family-medium btn-request-custom-hover btn btn-outline-primary px-4 py-11"
        [class.watch-video]="!requestButtonVisibility && landing "
      >
        {{'GENERAL.Login' | translate}}
      </a>
      <a *ngIf="requestButtonVisibility" [routerLink]="'/' + translateService.currentLang+'/request-demo'"
         (click)="onRequest()"
         class="btn-request font-family-medium btn-request-custom-hover btn btn-primary px-4 py-11 ml-4">
        {{'GENERAL.RequestDemo' | translate}}
        <app-icon icon="arrow-right" class="icon-light ml-2"></app-icon>
      </a>
    </div>
    <div class="d-inline-block d-lg-none cursor-pointer pr-3 py-3 headers-burger" (click)="toggleBurger()">
      <app-icon icon="burger" class="d-flex"></app-icon>
    </div>
  </div>

</header>
<div class="burger d-flex d-lg-none" *ngIf="burgerActive">
  <div class="burger-header border-bottom">
    <img src="assets/images/burger-logo.svg" alt=""/>
    <button class="bg-transparent bt-clean border-0 py-3" (click)="toggleBurger()">
       <app-icon icon="close"></app-icon>
     </button>
  </div>
  <div class="burger-body flex-grow-1">
    <nav>
      <ng-container *ngFor="let item of navItems">
        <a class="font-size-14 text-dark text-decoration-none font-family-medium d-block mb-32 cursor-pointer"
           [routerLink]="getRouterLink(item)"
           (click)="onNavigate(item)"
           *ngIf="item.url">{{ item.title | translate}}</a>
      </ng-container>
    </nav>
    <div class="border-top">
      <nav>
        <a class="font-size-14 text-decoration-none d-block my-32 font-color-gray-sec"
           [routerLink]="'/' + translateService.currentLang+'/faq/general'">{{'GENERAL.FAQ' | translate}}</a>
        <a class="font-size-14 text-decoration-none d-block my-32 font-color-gray-sec"
           href="https://optimo.ge/blog/" target="_blank" *ngIf="!isUz">{{ 'GENERAL.Blog' | translate }}
        </a>
      </nav>
    </div>
  </div>
  <div class="burger-footer py-sm-32 px-sm-64 px-3 py-4 text-center">
    <a [routerLink]="'/' + translateService.currentLang+'/request-demo'" (click)="onRequest()"
       class="btn btn-primary btn-block font-size-14 d-flex align-items-center justify-content-center h-48px"
    >
      {{'GENERAL.RequestDemo' | translate}}
    </a>
    <a class="btn btn-outline-primary h-48px font-size-14 d-flex align-items-center justify-content-center mt-3 mx-0"
       href="{{ onLogin() }}" target="_blank"
       (click)="trackToDashboard()">
      {{'GENERAL.Login' | translate}}
    </a>
  </div>
</div>
<ng-container *ngIf="burgerActive">
  <div class="burger-backdrop d-block d-lg-none" (click)="toggleBurger()"></div>
</ng-container>
